import React from "react";
import { useNavigate } from "react-router-dom";

import { Button, Tooltip } from "@nextui-org/react";
import Header from "@/components/header/header";
import RewardsTable from "@/components/rewards/rewardsTable";
import Loading from "@/components/loading/loading";
import RewardsInfo from "@/components/rewards/rewardsInfo";
import { useAppSelector } from "@/hooks/store";
import { useRewardReport } from "../api";
import ErrorMessage from "./errorMessage";

const Rewards = () => {
  const navigate = useNavigate();
  const { panelistId, accessCode } = useAppSelector((state) => state.auth);
  const [{ data, loading }] = useRewardReport(accessCode, panelistId);
  const savedPoints = data?.total_points_to_redeem;
  const lifetimePoints = data?.total_points;
  const convertNumber = (amount: number, decimalPlaces: number = 0) => {
    return amount.toLocaleString("en", {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces,
    });
  };

  if (loading) return <Loading />;
  if (!data?.success) return <ErrorMessage />;

  return (
    <>
      <div>
        <section className="mb-10">
          <Header size="h2">Current saved reward pot</Header>
          <p className="mb-6">
            You have {convertNumber(savedPoints)} points saved which is the
            equivalent of £{convertNumber(savedPoints / 1000, 2)}.
          </p>
          {/* TODO: Add link to cash in reward */}
          <Button color="secondary" onClick={() => null}>
            Cash in reward
          </Button>
        </section>
        <Header size="h2">Lifetime rewards</Header>
        <section className="mb-10 flex flex-col gap-4">
          <p>
            In total you have earned {convertNumber(lifetimePoints)} points
            which is the equivalent of £
            {convertNumber(lifetimePoints / 1000, 2)}.
          </p>
          <div>
            <Button
              type="submit"
              color="secondary"
              onClick={() =>
                setTimeout(() => {
                  navigate("/");
                }, 200)
              }
            >
              Start another session
            </Button>
          </div>
        </section>
        <section className="mb-10">
          <Header size="h2">Session History</Header>
          <RewardsTable data={data?.data_list} />
        </section>
      </div>
      <Tooltip content={<RewardsInfo />} color="secondary">
        <div className="absolute top-5 right-5">
          <Button color="primary" variant="light" disableRipple>
            What do you get rewarded for?
          </Button>
        </div>
      </Tooltip>
    </>
  );
};

export default Rewards;
