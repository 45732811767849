import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Link, Button, Checkbox } from "@nextui-org/react";
import { useForm, SubmitHandler } from "react-hook-form";

import { createPanelist } from "@/api/index";
import { useAlertContext } from "@/contexts/alertContext";
import { disabledTimer } from "@/utils/helpers";
import Input from "@/components/input/input";
import ExternalHeader from "../header/externalHeader";
import TermsModal from "../termsModal/termsModal";
import { useQuery } from "@/hooks/useQuery";
import { DEFAULT_PANEL_CODE } from "@/utils/constants";

interface ISignUpForm {
  email: string;
  terms: boolean;
}

interface SignUpFormProps {
  setEmail: Dispatch<SetStateAction<string>>;
}

const SignUpForm: React.FC<SignUpFormProps> = ({ setEmail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setFocus,
  } = useForm<ISignUpForm>();
  const [isDisabled, setIsDisabled] = useState(false);
  const { addAlert } = useAlertContext();
  const [isOpen, setIsOpen] = useState(false);
  const query = useQuery();

  // default panel code is fp but can be changed with query parameter panelCode
  const panelCode = query.get("panelCode") || DEFAULT_PANEL_CODE;

  const onSubmit: SubmitHandler<ISignUpForm> = async ({ email }) => {
    disabledTimer(setIsDisabled);

    try {
      await createPanelist(email, panelCode);
      setEmail(email);
    } catch (e) {
      addAlert({
        message: e?.message,
        type: "error",
        timeout: 5,
      });
    }
  };

  useEffect(() => {
    setFocus("email");
  }, [setFocus]);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ExternalHeader title="Sign Up" />
        <Input
          label="Email Address"
          type="email"
          placeholder="Username"
          register={register("email", {
            required: "Please enter your email address",
            pattern: {
              value: /^\S+@\S+$/i,
              message: "Please enter a valid email address",
            },
          })}
          autoComplete="email"
          errorMessage={errors?.email?.message}
        />
        <Checkbox
          {...register("terms", {
            required: "You must accept the terms and conditions",
          })}
        >
          <div className="inline-flex gap-1 items-center">
            <p className="block text-sm">I have read and agree to the</p>
            <Button
              color="primary"
              onClick={() => setIsOpen(true)}
              size="md"
              variant="light"
              className="data-[hover=true]:bg-white p-0"
            >
              terms and conditions
            </Button>
          </div>
        </Checkbox>
        <div className="text-danger text-xs mb-4">{errors?.terms?.message}</div>
        <div className="flex items-center justify-content gap-9">
          <Button type="submit" color="secondary" disabled={isDisabled}>
            Sign Up
          </Button>
          <Link href="/">Already have an account?</Link>
        </div>
      </form>
      <TermsModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default SignUpForm;
